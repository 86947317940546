import React, { useEffect } from 'react';
import Select from 'react-select';

function compressSize(size) {
    if (typeof size === 'string') {
        return size;
    }
    let ending = 'b';
    if (size > 1000) {
        size = size / 1000;
        ending = 'kb';
    }
    if (size > 1000) {
        size = size / 1000;
        ending = 'mb';
    }
    return `${size.toFixed(2)}${ending}`;
}
async function fetchAndSetBlobSize(uri, setState) {
    const blob = await fetch(uri).then((r) => r.blob());
    setState(blob.size);
}

function RenderImage({ type, size = null, compression = null, ext = 'jpg', extraText = null }) {
    const [fileSize, setFileSize] = React.useState('nacitava sa...');
    let uri = 'https://test.teibl.app/files/tests/';
    if (!size || !compression) {
        uri += `original_${type}.${ext}`;
    } else {
        uri += `${compression}_${type}-${size}.${ext}`;
    }
    React.useEffect(() => {
        fetchAndSetBlobSize(uri, setFileSize);
    }, []);
    return (
        <div>
            <h5>Veľkosť: {`${compressSize(fileSize)} ${extraText ? extraText : ''}`}</h5>
            <img style={{ backgroundColor: 'black' }} src={uri} />
        </div>
    );
}

function RenderImage2({ type, size }) {
    const [fileSize, setFileSize] = React.useState('nacitava sa...');
    let uri = `https://test.teibl.app/files/tests/size-${type}-${size}.jpg`;
    React.useEffect(() => {
        fetchAndSetBlobSize(uri, setFileSize);
    }, []);
    return (
        <div>
            <h5>Veľkosť: {`${compressSize(fileSize)} Rozmery: ${size}x${size}`}</h5>
            <img style={{ backgroundColor: 'black' }} src={uri} />
        </div>
    );
}

const quality = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];

export default function LacoExperiment() {
    const [size1ShowQuality, setSize1ShowQuality] = React.useState(
        localStorage.getItem('size1ShowQuality')
            ? JSON.parse(localStorage.getItem('size1ShowQuality'))
            : ['orig', ...quality]
    );
    const [size2ShowQuality, setSize2ShowQuality] = React.useState(
        localStorage.getItem('size2ShowQuality')
            ? JSON.parse(localStorage.getItem('size2ShowQuality'))
            : quality
    );
    const [size3ShowQuality, setSize3ShowQuality] = React.useState(
        localStorage.getItem('size3ShowQuality')
            ? JSON.parse(localStorage.getItem('size3ShowQuality'))
            : quality
    );
    const [size4ShowQuality, setSize4ShowQuality] = React.useState(
        localStorage.getItem('size4ShowQuality')
            ? JSON.parse(localStorage.getItem('size4ShowQuality'))
            : quality
    );
    const [switchx, setSwitchx] = React.useState(false);

    useEffect(() => {
        localStorage.setItem('size1ShowQuality', JSON.stringify(size1ShowQuality));
    }, [size1ShowQuality]);
    useEffect(() => {
        localStorage.setItem('size2ShowQuality', JSON.stringify(size2ShowQuality));
    }, [size2ShowQuality]);
    useEffect(() => {
        localStorage.setItem('size3ShowQuality', JSON.stringify(size3ShowQuality));
    }, [size3ShowQuality]);
    useEffect(() => {
        localStorage.setItem('size4ShowQuality', JSON.stringify(size4ShowQuality));
    }, [size4ShowQuality]);

    return (
        <div className="overflow-y overflow-x">
            <button
                onClick={() => {
                    setSize1ShowQuality(['orig', ...quality]);
                    setSize2ShowQuality(quality);
                    setSize3ShowQuality(quality);
                    setSize4ShowQuality(quality);
                }}
            >
                Reset all
            </button>
            <button
                onClick={() => {
                    setSwitchx(!switchx);
                }}
            >
                To sizes
            </button>
            {switchx && (
                <div>
                    <h1>Veľkosti</h1>
                    {['cat', 'food', 'chef'].map((type) => (
                        <div className="flex-row m-b-40" key={type}>
                            {[
                                100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200,
                            ].map((size) => (
                                <RenderImage2 size={size} type={type} key={size} />
                            ))}
                        </div>
                    ))}
                </div>
            )}
            <h1>Povodny obrazok</h1>
            <div className="flex-row">
                <RenderImage type="cat" ext="png" />
                <RenderImage type="chef" />
                <RenderImage type="food" ext="png" />
            </div>
            <h1>Kompresia - povodna velkost</h1>
            <div>
                {['cat', 'chef', 'food'].map((type) => (
                    <div key={type}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            isMulti
                            options={['orig', ...quality].map((num) => ({
                                label: num.toString(),
                                value: num,
                            }))}
                            value={size1ShowQuality.map((num) => ({ label: num, value: num }))}
                            onChange={(e) => {
                                setSize1ShowQuality(
                                    e.map((num) => num.value).sort((x, y) => (x > y ? -1 : 1))
                                );
                            }}
                        />

                        <div className="flex-row m-b-40">
                            {size1ShowQuality.map((num) =>
                                num === 'orig' ? (
                                    <RenderImage
                                        key={num}
                                        type={type}
                                        ext={type !== 'chef' ? 'png' : 'jpg'}
                                        extraText="Kvalita: Originál"
                                    />
                                ) : (
                                    <RenderImage
                                        size="orig"
                                        key={num}
                                        compression={num}
                                        type={type}
                                        extraText={`Kvalita: ${num}`}
                                    />
                                )
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <h1>Kompresia - 600px</h1>
            <div>
                {['cat', 'chef', 'food'].map((type) => (
                    <div key={type}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            isMulti
                            options={['reset', ...quality].map((num) => ({
                                label: num.toString(),
                                value: num,
                            }))}
                            value={size2ShowQuality.map((num) => ({ label: num, value: num }))}
                            onChange={(e) => {
                                if (e.some((it) => it.value === 'reset')) {
                                    setSize2ShowQuality(quality);
                                } else {
                                    setSize2ShowQuality(
                                        e.map((num) => num.value).sort((x, y) => (x > y ? -1 : 1))
                                    );
                                }
                            }}
                        />
                        <div className="flex-row m-b-40">
                            {size2ShowQuality.map((num) => (
                                <RenderImage
                                    size="600"
                                    key={num}
                                    compression={num}
                                    type={type}
                                    extraText={`Kvalita: ${num}`}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <h1>Kompresia - 300px</h1>

            <div>
                {['cat', 'chef', 'food'].map((type) => (
                    <div key={type}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            isMulti
                            options={['reset', ...quality].map((num) => ({
                                label: num.toString(),
                                value: num,
                            }))}
                            value={size3ShowQuality.map((num) => ({ label: num, value: num }))}
                            onChange={(e) => {
                                if (e.some((it) => it.value === 'reset')) {
                                    setSize3ShowQuality(quality);
                                } else {
                                    setSize3ShowQuality(
                                        e.map((num) => num.value).sort((x, y) => (x > y ? -1 : 1))
                                    );
                                }
                            }}
                        />
                        <div className="flex-row m-b-40">
                            {size3ShowQuality.map((num) => (
                                <RenderImage
                                    size="300"
                                    compression={num}
                                    type={type}
                                    extraText={`Kvalita: ${num}`}
                                    key={num}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <h1>Kompresia - 150px</h1>
            <div>
                {['cat', 'chef', 'food'].map((type) => (
                    <div key={type}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            isMulti
                            options={['reset', ...quality].map((num) => ({
                                label: num.toString(),
                                value: num,
                            }))}
                            value={size4ShowQuality.map((num) => ({ label: num, value: num }))}
                            onChange={(e) => {
                                if (e.some((it) => it.value === 'reset')) {
                                    setSize4ShowQuality(quality);
                                } else {
                                    setSize4ShowQuality(
                                        e.map((num) => num.value).sort((x, y) => (x > y ? -1 : 1))
                                    );
                                }
                            }}
                        />
                        <div className="flex-row m-b-40">
                            {size4ShowQuality.map((num) => (
                                <RenderImage
                                    size="150"
                                    compression={num}
                                    type={type}
                                    extraText={`Kvalita: ${num}`}
                                    key={num}
                                />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
