import React from 'react';
import ReactDOM from 'react-dom/client';
import LacoExperiment from 'experiment';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<LacoExperiment />);
